import { useEffect } from "react";

const NaverAnalytics = () => {
  useEffect(() => {
    // 네이버 스크립트 추가
    const script = document.createElement("script");
    script.src = "//wcs.naver.net/wcslog.js";
    script.type = "text/javascript";
    script.async = true;

    // 스크립트를 <head>에 추가
    document.head.appendChild(script);

    // 초기화 코드 추가
    const inlineScript = document.createElement("script");
    inlineScript.type = "text/javascript";
    inlineScript.innerHTML = `
      if (!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "f27b1ed6206278";
      if (window.wcs) {
        wcs_do();
      }
    `;
    document.head.appendChild(inlineScript);

    // Cleanup: 컴포넌트 언마운트 시 스크립트 제거
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return null; // 렌더링할 내용 없음
};

export default NaverAnalytics;
